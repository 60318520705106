import React from 'react';
import { CSSTransition } from 'react-transition-group';

// styles
import './menu.scss';

export default function Menu(props) {
    const { menu, banner, entries } = props;

    return (
        <CSSTransition classNames="menu-transition" in={menu} appear unmountOnExit timeout={350}>
            <div className={`menu z-menu ${banner ? 'menu--margin' : ''}`}>
                <div className="menu__background" />
                <div className="menu__content">
                    {entries.map(entry => (
                        <a
                            key={entry.id}
                            href={`/${entry.uri}`}
                            className="menu__nav-item uppercase">
                            <h1 className="h1 center-text">{entry.title}</h1>
                        </a>
                    ))}
                </div>
            </div>
        </CSSTransition>
    );
}
