import { GraphQLClient } from 'graphql-request';

const shopifyGqlUrl = process.env.GATSBY_SHOPIFY_STOREFRONT_URL;
const shopifyGqlToken = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN;

export const client = new GraphQLClient(shopifyGqlUrl, {
    headers: {
        'X-Shopify-Storefront-Access-Token': shopifyGqlToken,
    },
});

export default client;
