import React, { useContext } from 'react';
import { Link } from 'gatsby';

// contexts
import { HeaderContext } from '@src/contexts/Header';

// components
import LinkButton from '@src/components/button/LinkButton';
import CartButton from '@src/components/button/CartButton';

// svgs
import Burger from '@src/svgs/burger.svg';
import Logo from '@src/svgs/logo.svg';

// styles
import './header.scss';

export default function Header(props) {
    // eslint-disable-next-line no-unused-vars
    const [state] = useContext(HeaderContext);
    const { toggleMenu, menuOn, entries } = props;

    const burger = (
        <button
            className="header__cta header__cta--burger button button--small"
            aria-label="Toggle menu"
            type="button"
            onClick={toggleMenu}>
            <span className="button__inner">
                <span className="button__text">
                    <Burger />
                </span>
            </span>
        </button>
    );
    const close = (
        <div className="menu__close">
            <button
                type="button"
                aria-label="Toggle menu"
                onClick={toggleMenu}
                className="button button--small button--white">
                <span className="button__inner">
                    <span className="button__text">X</span>
                </span>
            </button>
        </div>
    );

    return (
        <div className="header z-header">
            {state.white ? null : <div className="header__background" />}
            <div className="header__copy z-over-rain">
                {menuOn ? close : burger}

                <Link
                    to="/"
                    className={`header__Logo-wrapper link${state.white ? ' white' : ''} z-2`}>
                    <Logo className="header__Logo" />
                </Link>

                <ul className="flex z-2">
                    {entries.map(entry => (
                        <li
                            key={entry.id}
                            className="header__nav-item header__nav-item--mobile-hide">
                            <LinkButton
                                to={`/${entry.uri}`}
                                copy={entry.title}
                                white={state.white}
                            />
                        </li>
                    ))}
                    <li className="header__nav-item">
                        <CartButton
                            white={state.white}
                            copy="Cart"
                            classes="header__cta header__cta--cart"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
}
