import React, { createContext, useContext, useState, useEffect } from 'react';
import { isBrowser } from '@src/utils/SSR';

const defaultValues = {
    width: 0,
    isMobile: false,
    isPhone: false,
};

export const ViewportContext = createContext(defaultValues);

export const ViewportProvider = ({ children }) => {
    const [width, setWidth] = useState(isBrowser() ? window.innerWidth : 0);
    const [isSmallDesktop, setIsSmallDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isPhone, setIsPhone] = useState(false);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize.bind(this));
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsSmallDesktop(width < 1280);
        setIsMobile(width < 1000);
        setIsPhone(width <= 768);
    }, [width]);

    return (
        <ViewportContext.Provider
            value={{ ...defaultValues, width, isSmallDesktop, isMobile, isPhone }}>
            {children}
        </ViewportContext.Provider>
    );
};

export const useViewport = () => {
    const { width, isSmallDesktop, isMobile, isPhone } = useContext(ViewportContext);
    return { width, isSmallDesktop, isMobile, isPhone };
};
