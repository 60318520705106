import React from 'react';

// components
import LinkButton from '../button/LinkButton';

// styles
import './footer-summary.scss';

export default function FooterSummary(props) {
    const { data } = props;

    return (
        <div className="footer-summary wavy wavy--lavender relative white">
            <div className="footer-summary__inner">
                <div className="grid grid--2 m-42">
                    <div
                        className="rich-text"
                        dangerouslySetInnerHTML={{ __html: data.summaryHeader }}
                    />
                    <div
                        className="rich-text"
                        dangerouslySetInnerHTML={{ __html: data.summaryParagraph }}
                    />
                </div>

                {data.entryLink[0] ? (
                    <div className="flex justify-center">
                        <LinkButton
                            large
                            to={`/${data.entryLink[0].uri}`}
                            copy={data.entryLink[0].title}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
