import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

export default function Asset(props) {
    const { data, border, title, round, square, contain, link, max } = props;
    const image = getImage(data);

    const classes = classNames({
        'round-borders': !square,
        'border-image': link,
        border,
        round,
        'gatsby-image-wrapper--max': max,
        'gatsby-image-wrapper--contain': contain,
    });

    return image ? (
        <GatsbyImage
            fit="contain"
            className={classes}
            image={image}
            alt={title || 'image'}
            loading="eager"
        />
    ) : null;
}
