import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ShopProvider } from '@src/contexts/Shop';

export default ({ element }) => (
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    <ShopProvider>
        <ParallaxProvider>{element}</ParallaxProvider>
    </ShopProvider>
);
