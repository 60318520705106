import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

export default function LinkButton(props) {
    const { to, copy, large, classes, white } = props;

    const btnClasses = classNames({
        button: true,
        'button--white': white,
        'button--large': white ? false : large,
        'button--white-large': white ? !!large : false,
        uppercase: true,
    });

    return (
        <Link to={to} className={`${btnClasses} ${classes}`}>
            <span className="button__inner">
                <span className="button__text">{copy}</span>
            </span>
        </Link>
    );
}
