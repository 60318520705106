import React from 'react';
import { CSSTransition } from 'react-transition-group';

// styles
import './banner.scss';

class Banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    componentDidMount() {
        const animationSession =
            typeof sessionStorage === `undefined` ? null : sessionStorage.getItem('animateBanner');

        if (animationSession !== 'false') {
            this.setState({ show: true });
            sessionStorage.setItem('animateBanner', false);
        }
    }

    render() {
        const { show } = this.state;
        const { banner, message, closeBanner } = this.props;

        return (
            <CSSTransition classNames="banner-transition" in={show} timeout={1650}>
                <div
                    className={`flex relative align-center justify-center z-banner banner ${
                        !show && banner ? 'banner--height' : ''
                    } ${!banner ? 'banner--zero' : ''}`}>
                    <div className="banner__copy full">
                        <div
                            className="white center-text"
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                        <button
                            className="banner__close link white bold flex align-center justify-center center-text"
                            type="button"
                            onClick={closeBanner}>
                            X
                        </button>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default Banner;
