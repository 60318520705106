export const formatPrice = (currency, value) =>
    Intl.NumberFormat('en-US', {
        currency,
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(value);

export const getCurrencySymbol = (currency, locale = undefined) => {
    if (!currency) {
        return null;
    }
    const formatter = Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
    });
    const parts = formatter.formatToParts(100);
    const { value: symbol } = parts.find(part => part.type === 'currency');
    const formatted = formatter.format(100);
    const symbolAtEnd = formatted.endsWith(symbol);
    return { symbol, symbolAtEnd };
};
