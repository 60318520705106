/* eslint-disable no-underscore-dangle */
import React, { createRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone';

// utils
import Loader from '@src/components/loader/Loader';
import { locationWeather } from '@src/utils/Weather';

// components

// styles
import './weatherWidget.scss';

// svgs
import Rain from '@src/svgs/weather-rain.svg';
import Sun from '@src/svgs/weather-sun.svg';
import Cloudy from '@src/svgs/weather-cloudy.svg';
import Snow from '@src/svgs/weather-snow.svg';
import Partly from '@src/svgs/weather-partly-cloud.svg';
import Night from '@src/svgs/weather-night-clear.svg';
import NightClouds from '@src/svgs/weather-night-cloudy.svg';

class WeatherWidget extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            // isLoading: false,
            time: moment().tz('US/Central').format('LT'),
        };

        this.timeRef = createRef(null);
        this.weatherData = null;
        this.loadingIndicatorTimeout = null;
    }

    componentDidMount() {
        this._isMounted = true;

        // animate the widget the first time a user sees the site but not every page
        const animationSession =
            typeof sessionStorage === `undefined` ? null : sessionStorage.getItem('animateWeather');

        if (animationSession !== 'false') {
            this.setState({ show: true });
            sessionStorage.setItem('animateWeather', false);
        }

        // update the clock
        setInterval(this.updateTime.bind(this), 1000);
        // this.loadingIndicatorTimeout = setTimeout(() => this.setState({ ...this.state, isLoading: true }), 500);

        // update the weather
        if (!this.weatherData) this.getWeather();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getWeather = async () => {
        const result = await locationWeather();

        clearTimeout(this.loadingIndicatorTimeout);
        this.weatherData = result.success ? result.data : {};
    };

    // eslint-disable-next-line class-methods-use-this
    setIcon(id) {
        switch (id) {
            case '01d':
                return <Sun />;
            case '01n':
                return <Night />;
            case '02d':
                return <Partly />;
            case '02n':
                return <NightClouds />;
            case '03d':
                return <Cloudy />;
            case '04d':
                return <Cloudy />;
            case '09d':
                return <Rain />;
            case '10d':
                return <Rain />;
            case '11d':
                return <Rain />;
            case '13d':
                return <Snow />;
            default:
                return <Sun />;
        }
    }

    updateTime() {
        if (this._isMounted) this.setState({ time: moment().tz('US/Central').format('LT') });
    }

    render() {
        const { show, time } = this.state;
        const iconId = this.weatherData ? this.weatherData.weather.icon : '01d';

        return (
            <CSSTransition classNames="weather-transition" in={show} timeout={1200}>
                <div className="weather-widget z-weather flex wrap direction-column justify-center">
                    <p className="weather-widget__info flex align-center justify-evenly">
                        <span className="weather-widget__svg relative">
                            {this.weatherData ? this.setIcon(iconId) : <Loader weather />}
                        </span>
                        <span className="relative">
                            {this.weatherData
                                ? `${Math.floor(this.weatherData.main.temp)}°F`
                                : null}
                        </span>
                    </p>
                    <p className="weather-widget__info flex align-center justify-evenly">
                        <span>Austin</span>
                        <span className="weather-widget__info--m-hide">{time}</span>
                    </p>
                </div>
            </CSSTransition>
        );
    }
}

export default WeatherWidget;
