export const locationWeather = async () => {
    try {
        const result = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?id=4671654&appid=28a6e14b05d80676b0b28994cbdb5bbd&units=imperial`
        );

        if (result.status === 200) {
            return { success: true, data: await result.json() };
        }

        return { success: false, error: result.statusText };
    } catch (ex) {
        return { success: false, error: ex.message };
    }
};

export default locationWeather;
