import React, { useState, useEffect } from 'react';
import { useShop } from '@src/contexts/Shop';
import { useViewport } from '@src/contexts/Viewport';
import CartIcon from '@src/svgs/cart.svg';
import Button from './Button';

const CartIndicator = ({ count }) => (
    <div className="cart__indicator flex align-center justify-center z-2">{count}</div>
);

export default function CartButton({ copy, classes, white }) {
    const { cart, toggle } = useShop();
    const { isPhone } = useViewport();
    const [count, setCount] = useState(0);

    useEffect(() => {
        let totalCount = 0;
        if (cart?.lines?.edges?.length > 0) {
            cart?.lines?.edges?.forEach(({ node }) => {
                totalCount += node.quantity;
            });
        }
        setCount(totalCount);
    }, [cart]);

    return (
        <div className="relative">
            {isPhone ? (
                <button
                    type="button"
                    className={`button button--small ${classes}`}
                    onClick={toggle}>
                    <span className="button__inner">
                        <span className="button__text">
                            <CartIcon />
                        </span>
                    </span>
                </button>
            ) : (
                <Button copy={copy} white={white} classes={classes} onClick={toggle} />
            )}
            {count > 0 ? <CartIndicator count={count} /> : null}
        </div>
    );
}
