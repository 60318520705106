import React, { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

export const HeaderContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const HeaderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <HeaderContext.Provider value={[state, dispatch]}>{children}</HeaderContext.Provider>;
};
