export const reducer = (state, action) => {
    switch (action.type) {
        case 'overlay_toggle':
            return {
                ...state,
                white: !state.white,
            };

        default:
            return state;
    }
};

export const initialState = {
    white: false,
};
