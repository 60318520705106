import React, { useEffect, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { debounce } from 'lodash';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import Asset from '@src/components/asset/Asset';
import Button from '@src/components/button/Button';

// styles
import './line-item.scss';

export default function LineItem({ item }) {
    const {
        products: { nodes: products },
    } = useStaticQuery(
        graphql`
            {
                products: allCraftShopProductEntry(filter: { sectionHandle: { eq: "shop" } }) {
                    nodes {
                        id: remoteId
                        uri
                        title
                        shopifyProduct
                        richTextStandard
                        multipleImages {
                            url
                            title
                            ... on Craft_images_Asset {
                                id
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const productIdParts = item.merchandise.product.id.split('/');
    const productId = productIdParts[productIdParts.length - 1];
    const product = products.find(obj => obj.shopifyProduct[0] === productId);

    const image = product.multipleImages[0];

    const { removeItem, updateItem } = useShop();
    const [quantity, setQuantity] = useState(item.quantity);
    const [price, setPrice] = useState('');

    const [subPrice, setSubPrice] = useState(0);

    useEffect(() => {
        setPrice(
            formatPrice(
                item.merchandise.priceV2.currencyCode,
                Number(item.merchandise.priceV2.amount) * quantity
            )
        );

        if (item.sellingPlanAllocation?.priceAdjustments[0]) {
            setSubPrice(
                formatPrice(
                    item.sellingPlanAllocation?.priceAdjustments[0]?.price.currencyCode,
                    Number(item.sellingPlanAllocation?.priceAdjustments[0]?.price.amount) * quantity
                )
            );
        }
    }, [item, quantity]);

    const handleRemove = () => removeItem(item.id);

    const update = debounce(value => updateItem(item.id, value), 150);

    const debouncedUpdate = useCallback(value => update(value), []);

    const handleQuantityChange = value => {
        if (value !== '' && Number(value) < 1) {
            return;
        }
        setQuantity(value);
        if (Number(value) >= 1) {
            debouncedUpdate(value);
        }
    };

    function doIncrement() {
        handleQuantityChange(Number(quantity || 0) + 1);
    }

    function doDecrement() {
        handleQuantityChange(Number(quantity || 0) - 1);
    }

    return (
        <div className="flex align-center line-item m-64">
            <Button white small copy="X" onClick={handleRemove} />

            <div className="line-item__image">
                {image ? (
                    <Asset data={image.imageFile} title={image.title} noBorder square />
                ) : null}
            </div>

            <div className="">
                <h4 className="h4 serif paragraph--large">{product.title}</h4>
                {subPrice !== 0 ? (
                    <h5
                        style={{ fontSize: '1.5rem', lineHeight: '1.2', margin: '0 0 16px' }}
                        className="h5 normal-weight m-6">
                        {item.merchandise.title}{' '}
                        <span style={{ textTransform: 'capitalize' }}>
                            {item.sellingPlanAllocation?.sellingPlan.name}
                        </span>
                    </h5>
                ) : (
                    <h5
                        style={{ fontSize: '1.5rem', lineHeight: '1.2', margin: '0 0 16px' }}
                        className="h5 normal-weight m-6">
                        {item.merchandise.title}
                    </h5>
                )}

                <div className="flex wrap align-center m-8">
                    <h6
                        className="h5 bold m-0"
                        style={{ marginRight: '10px', marginBottom: '6px' }}>
                        QTY
                    </h6>
                    <div className="flex justify-between align-center input-button input-button--outline-purple input-button--no-cursor">
                        <button
                            className="paragraph--large p-8 cursor white link"
                            type="button"
                            onClick={doDecrement}>
                            –
                        </button>
                        <div>{quantity}</div>
                        <button
                            className="paragraph--large p-8 cursor white link"
                            type="button"
                            onClick={doIncrement}>
                            +
                        </button>
                    </div>
                </div>

                {subPrice !== 0 ? (
                    <h5 style={{ marginTop: '4px' }}>
                        <span style={{ textDecoration: 'line-through', opacity: 0.8 }}>
                            {price}
                        </span>{' '}
                        {subPrice}
                    </h5>
                ) : (
                    <h5>{price}</h5>
                )}
            </div>
        </div>
    );
}
