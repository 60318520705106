import React, { useEffect } from 'react';

// styles
import './newsletter.scss';
import { graphql, useStaticQuery } from 'gatsby';

export default function Newsletter(props) {
    const data = useStaticQuery(
        graphql`
            {
                globals: allCraftGlobalSetInterface(
                    filter: { id: {}, handle: { eq: "newsletterId" } }
                ) {
                    nodes {
                        ... on Craft_newsletterId_GlobalSet {
                            plainText
                        }
                    }
                }
            }
        `
    );

    const id = data.globals.nodes[0].plainText;
    const { inline } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="mc_embed_signup" className="mc_embed_signup">
            {id && (
                <form
                    className="newsletter validate"
                    method="POST"
                    action={`https://drinkheartwater.us21.list-manage.com/subscribe/post?u=2e4b348bb11c4a23a01a669ad&amp;id=${id}&amp;f_id=00fa54e1f0`}
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    target="_blank"
                    noValidate
                    acceptCharset="UTF-8">
                    <div className="flex full align-center justify-between nowrap">
                        <input
                            className={`input uppercase input--full ${
                                inline ? 'input--border' : ''
                            }`}
                            type="email"
                            placeholder="email"
                            name="EMAIL"
                            id="mce-EMAIL"
                            required
                        />
                        <div type="button" className="button button--white">
                            <span className="button__inner">
                                <input
                                    className="button__text button__text--purple uppercase"
                                    style={{ fontWeight: '600', fontSize: '16px', margin: 0 }}
                                    type="submit"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    defaultValue="Sign up"
                                />
                            </span>
                        </div>
                    </div>

                    <div id="mce-responses" className="w-full">
                        <div
                            className="response"
                            id="mce-error-response"
                            style={{ display: 'none', fontSize: '16px' }}
                        />
                        <div
                            className="response"
                            id="mce-success-response"
                            style={{ display: 'none', fontSize: '16px' }}
                        />
                    </div>

                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                        <input
                            type="text"
                            name="b_2e4b348bb11c4a23a01a669ad_8ace8bd752"
                            tabIndex="-1"
                        />
                    </div>
                </form>
            )}
        </div>
    );
}
