import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// components
import FooterSummary from './FooterSummary';
import Newsletter from '../newsletter/Newsletter';

// svgs
import DayJob from '../../svgs/logo-dayjob.svg';
import FacebookIcon from '../../svgs/icon-facebook.svg';
import InstagramIcon from '../../svgs/icon-instagram.svg';
import TikTokIcon from '../../svgs/icon-tiktok.svg';
import TwitterIcon from '../../svgs/icon-twitter.svg';
import Logo from '../../svgs/logo.svg';

// styles
import './footer.scss';

export default function Footer() {
    const data = useStaticQuery(
        graphql`
            {
                globals: allCraftGlobalSetInterface {
                    nodes {
                        ... on Craft_footerContent_GlobalSet {
                            richTextStandard
                            handle
                            entryMatrix {
                                ... on Craft_entryMatrix_entryBlock_BlockType {
                                    entryList {
                                        uid
                                        slug
                                        title
                                        uri
                                    }
                                }
                            }
                            footerSummaryMatrix {
                                ... on Craft_footerSummaryMatrix_footerSummaryBlock_BlockType {
                                    summaryLightswitch
                                    summaryHeader
                                    summaryParagraph
                                    entryLink {
                                        title
                                        uri
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const footerContent = data.globals.nodes.filter(obj => obj.handle === 'footerContent')[0];

    const shopEntries = footerContent.entryMatrix[0]?.entryList;
    const information = footerContent.entryMatrix[1]?.entryList;
    const contactList = footerContent.entryMatrix[2]?.entryList;
    const newsletterCopy = footerContent.richTextStandard;
    const summary = footerContent.footerSummaryMatrix[0];

    return (
        <>
            {summary && summary.summaryLightswitch ? <FooterSummary data={summary} /> : null}

            <div className="footer wavy wavy--purple z-footer">
                <div className="footer__row">
                    {shopEntries?.length ? (
                        <div className="footer__item">
                            <h2 className="h2 uppercase footer__header">Shop</h2>
                            <ul className="footer__list">
                                {shopEntries.map(entry => (
                                    <li key={`shop-${entry.uid}`}>
                                        <Link to={`/${entry.uri}`} className="footer__link">
                                            <p className="large">{entry.title}</p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    {information?.length ? (
                        <div className="footer__item">
                            <h2 className="h2 uppercase footer__header">Info</h2>
                            <ul className="footer__list">
                                {information.map(entry => (
                                    <li key={`info-${entry.uid}`}>
                                        <Link to={`/${entry.uri}`} className="footer__link">
                                            <p className="large">{entry.title}</p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    {contactList?.length ? (
                        <div className="footer__item">
                            <h2 className="h2 uppercase footer__header">Contact</h2>
                            <ul className="footer__list">
                                {contactList.map(entry => (
                                    <li key={`contact-${entry.uid}`}>
                                        <Link to={`/${entry.uri}`} className="footer__link">
                                            <p className="large">{entry.title}</p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    <div className="footer__item footer__item--newsletter">
                        <h2 className="h2 uppercase footer__header">Newsletter</h2>
                        <div
                            className="rich-text m-24"
                            dangerouslySetInnerHTML={{ __html: newsletterCopy }}
                        />
                        <Newsletter white />
                    </div>
                </div>

                <div className="footer__row footer__row--large">
                    <div className="footer__item" style={{ margin: 0 }}>
                        <div className="footer__social-icon">
                            <a
                                href="https://facebook.com/drinkhw"
                                aria-label="Drink Heart Water Facebook"
                                target="_blank"
                                rel="noreferrer"
                                className="link">
                                <FacebookIcon />
                            </a>
                        </div>
                        <div className="footer__social-icon">
                            <a
                                href="https://instagram.com/heartwater"
                                aria-label="Drink Heart Water Instagram"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                                style={{ width: 42 }}>
                                <InstagramIcon />
                            </a>
                        </div>
                        <div className="footer__social-icon">
                            <a
                                href="https://tiktok.com/@drinkheartwater"
                                aria-label="Drink Heart Water TikTok"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                                style={{ width: 42 }}>
                                <TikTokIcon />
                            </a>
                        </div>
                        <div className="footer__social-icon">
                            <a
                                href="https://twitter.com/drinkheartwater"
                                aria-label="Drink Heart Water Twitter"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                                style={{ width: 42 }}>
                                <TwitterIcon />
                            </a>
                        </div>
                    </div>
                    <div className="footer__item" />
                    <div className="footer__item" style={{ margin: 0 }}>
                        <Link
                            to="/pages/terms-and-conditions"
                            className="footer__link"
                            style={{ margin: 0 }}>
                            <h2 className="h2 uppercase">Terms & Conditions</h2>
                        </Link>
                    </div>
                    <div className="footer__item" style={{ margin: 0 }}>
                        <Link
                            to="/pages/privacy-policy"
                            className="footer__link"
                            style={{ margin: 0 }}>
                            <h2 className="h2 uppercase">Privacy Policy</h2>
                        </Link>
                    </div>
                </div>

                <div className="footer__row">
                    <div className="footer__item footer__item--svg">
                        <Link to="/" className="footer__link">
                            <Logo />
                        </Link>
                    </div>
                    <div className="no-tablet" />

                    <div className="footer__item footer__item--dayjob">
                        <p className="bold uppercase">
                            Branding &amp; website
                            <br />
                            by{' '}
                            <a
                                href="https://dayjob.work/"
                                aria-label="Day Job"
                                target="_blank"
                                rel="noreferrer"
                                className="link">
                                <span className="footer__dayjob">
                                    <DayJob />
                                </span>
                            </a>
                        </p>
                    </div>
                    <div className="no-tablet" />
                </div>
            </div>
        </>
    );
}
