import React from 'react';
import './src/styles/app.scss';

import wrapWithProvider from './wrap-with-provider';
import Layout from './src/components/layout/Layout';

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement = ({ element, props }) => (
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Layout {...props}>{element}</Layout>
);

export function shouldUpdateScroll(prevRouterProps, { location }) {
    window.scrollTo(0, 0);
    const body = document.getElementsByTagName('body')[0];
    body.scrollTop = 0;
    return false;
}
