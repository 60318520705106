import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import Button from '@src/components/button/Button';
import LineItem from '@src/components/lineItem/LineItem';

// svgs
import Cloudy from '@src/svgs/weather-cloudy-white.svg';

// styles
import './cart.scss';

export default function Cart() {
    const { cart, isOpen, close } = useShop();
    const [hasItems, setHasItems] = useState(false);
    const [price, setPrice] = useState('');

    const handleCheckout = () => {
        window.open(cart.checkoutUrl);
    };

    useEffect(() => {
        setHasItems(cart?.lines?.edges?.length > 0);
    }, [cart]);

    useEffect(() => {
        if (cart?.estimatedCost?.totalAmount) {
            setPrice(
                formatPrice(
                    cart?.estimatedCost?.totalAmount.currencyCode,
                    cart?.estimatedCost?.totalAmount.amount
                )
            );
        }
    }, [cart]);

    return (
        <CSSTransition classNames="modal-transition" in={isOpen} appear unmountOnExit timeout={600}>
            <div className={`cart z-cart ${isOpen ? 'cart--active' : ''}`}>
                <div className="cart__background" />

                <section
                    style={{ height: hasItems ? '' : '100vh' }}
                    className="cart__content relative flex white">
                    <header className="cart__header flex align-center full justify-between m-32">
                        <h2 className="h1 m-0 lh-1">Cart</h2>
                        <Button type="button" white copy="X" classes="bold" onClick={close} />
                    </header>
                    {hasItems ? (
                        <>
                            <div className="cart__products full">
                                {cart.lines.edges.map(({ node }, i) => (
                                    <LineItem key={node.id} item={node} />
                                ))}
                            </div>
                            <footer className="cart__footer full">
                                <div className="cart__total uppercase flex full justify-between m-42">
                                    <h2 className="h2">Total</h2>
                                    <h2 className="h2">{price}</h2>
                                </div>
                                <div className="flex full justify-center">
                                    <Button large white copy="checkout" onClick={handleCheckout} />
                                </div>
                            </footer>
                        </>
                    ) : (
                        <h2 className="cart__empty h2 uppercase relative flex full justify-center align-center">
                            <span className="p-8">No items yet </span>
                            <span className="animation-ambient">
                                <Cloudy />
                            </span>
                        </h2>
                    )}
                </section>
            </div>
        </CSSTransition>
    );
}
