import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

// content
import { HeaderProvider } from '@src/contexts/Header';
import { useShop } from '@src/contexts/Shop';

// components
import Banner from '@src/components/banner/Banner';
import Cart from '@src/components/cart/Cart';
import Footer from '@src/components/footer/Footer';
import Header from '@src/components/header/Header';
import Menu from '@src/components/menu/Menu';
import RainWater from '@src/components/rainWater/RainWater';
import WeatherWidget from '@src/components/weatherWidget/WeatherWidget';
import { ViewportProvider } from '@src/contexts/Viewport';

export default function Layout({ location, children }) {
    // cart and product actions
    const { isOpen: cartOn } = useShop();

    // layout actions
    const [bannerOn, setBannerOn] = useState(false);
    const [menuOn, setMenuOn] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const { banner, globals } = useStaticQuery(
        graphql`
            {
                banner: craftBannerGlobalSet {
                    richTextStandard
                    lightswitch
                }
                globals: allCraftGlobalSetInterface(filter: { name: { eq: "Header Nav" } }) {
                    nodes {
                        ... on Craft_headerNav_GlobalSet {
                            entriesField {
                                id
                                url
                                uri
                                title
                                slug
                            }
                        }
                    }
                }
            }
        `
    );

    const entries = globals.nodes[0].entriesField;

    useEffect(() => {
        const bannerLocal =
            typeof localStorage === `undefined` ? null : localStorage.getItem('banner');

        if (bannerLocal !== 'false') {
            setBannerOn(banner.lightswitch);
            localStorage.setItem('banner', true);
        }
    }, [banner.lightswitch]);

    useEffect(() => {
        if (cartOn) {
            setModalOpen(true);
        } else {
            setModalOpen(false);
        }
    }, [cartOn]);

    const closeBanner = () => {
        setBannerOn(false);
        if (typeof localStorage !== `undefined`) localStorage.setItem('banner', false);
    };

    const toggleMenu = () => {
        if (menuOn) {
            setMenuOn(false);
            setModalOpen(false);
        } else {
            setMenuOn(true);
            setModalOpen(true);
        }
    };

    return (
        <ViewportProvider>
            <HeaderProvider>
                <Helmet htmlAttributes={{ class: modalOpen ? 'no-scroll' : null }} />
                <Banner
                    banner={bannerOn}
                    message={banner.richTextStandard}
                    closeBanner={closeBanner}
                />

                <Header
                    entries={entries}
                    sticky={bannerOn}
                    toggleMenu={toggleMenu}
                    menuOn={menuOn}
                />

                <Menu entries={entries} menu={menuOn} banner={bannerOn} />

                <Cart />

                <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
                    <div
                        className={`view-wrapper z-base ${bannerOn ? 'view-wrapper--margin' : ''}`}>
                        <TransitionGroup component={null}>
                            <CSSTransition
                                key={location.key}
                                classNames="page-transition"
                                timeout={500}>
                                <div>{children}</div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>

                <Footer />

                <RainWater />
                <WeatherWidget />
            </HeaderProvider>
        </ViewportProvider>
    );
}
