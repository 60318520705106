import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useViewport } from '@src/contexts/Viewport';

// styles
import './rain-water.scss';

export default function RainWater() {
    const videoRef = useRef();
    const [isClassicSafari, setClassicSafari] = useState(false);
    const [videoSize, setVideoSize] = useState('');
    const { isPhone, isMobile } = useViewport();

    const classes = classNames('rain-water', 'z-rain', {
        'rain-water--matte': isClassicSafari,
    });

    const checkForSafari = () => {
        if (videoRef.current.currentSrc.includes('matte')) {
            setClassicSafari(true);
        }
    };

    useEffect(() => {
        let size = '1080';
        if (isPhone) {
            size = '480';
        } else if (isMobile) {
            size = '720';
        }
        setVideoSize(size);
    }, [videoRef, isMobile, isPhone]);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener('loadeddata', checkForSafari);
            video.load();
        }
        return () => video?.removeEventListener('loadeddata', checkForSafari, true);
    }, [videoRef, videoSize]);

    return videoSize ? (
        <div className={classes}>
            <video ref={videoRef} className="rain-water__video" autoPlay loop muted playsInline>
                <source src={`/video/rain.${videoSize}.m4v`} type='video/mp4; codecs="hvc1"' />
                <source src={`/video/rain.${videoSize}.webm`} type='video/webm; codecs="vp9"' />
                <source src="/video/rain.matte.webm" type='video/webm; codecs="vp8"' />
            </video>
        </div>
    ) : null;
}
