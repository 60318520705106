import React from 'react';
import classNames from 'classnames';

export default function Button({ copy, large, classes, white, small, onClick, type }) {
    const btnClasses = classNames({
        button: true,
        'button--white': white,
        'button--large': large,
        'button--small': small,
        uppercase: true,
    });

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type || 'button'}
            className={`${btnClasses} ${classes || ''}`}
            onClick={onClick}>
            <span className="button__inner">
                <span className="button__text">{copy}</span>
            </span>
        </button>
    );
}
